<template>
    <div class="calendar-details-modal">
        <b-modal class="cpd-modal" scrollable :title="title" ref="modal" size="lg"
                 centered header-bg-variant="default" header-text-variant="dark" :ok-only="true" ok-variant="dark" ok-title="Close">
            <template slot="modal-header">
                <h5 style="margin-bottom: 0px;"><b-badge>{{type}}</b-badge> {{title}}</h5>
            </template>

          <ReadMore
              v-if="webinar"
              class="webinar-description"
              :action-disabled="webinar.registered"
              :action-callback="function() {registerForWebinar(webinar)}"
              :action-title="actionTitle(webinar)"
              more-str="See more..."
              less-str="Show less"
              :title="title"
              :text="webinar.webinarDescription"
              :start="start"
              :end="end"
              :max-chars="400">

            </ReadMore>
            <template slot="modal-footer">
                <b-button @click="$refs.modal.hide()" variant="dark">Close</b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>
    import ReadMore from '../../components/ReadMore'

    export default {
        name: "CalendarDetailsModal",
        components: {ReadMore},
        data() {
            return {
                loading: false,
                title: '',
                description: '',
                type: '',
                webinar: null,
                start:null,
                allDay:null,
                end:null
            }
        },
        methods: {
            show(event) {
                this.title = event.title;
                this.description = event.extendedProps.description;
                this.type = event.extendedProps.type;
                this.webinar = event.extendedProps.webinar;
                this.webinar.webinarDescription = this.webinar.webinarDescription.replace(/(?:\r\n|\r|\n)/g, '<br>');
                this.loading = false;
                this.start = event.start;
                this.allDay = event.allDay;
                this.end = event.end;
                this.$refs.modal.show();
            },
            registerForWebinar: function(webinar) {
                this.loading = true;
                this.$http.post(`webinars/${webinar.webinarIdStr}/register`).then(() => {
                    webinar.registered = true;
                }).catch((resp) => {
                    this.$httpError('Failed to register for webinar', resp);
                }).finally(() => {
                    this.loading = false;
                    this.$emit('event-updated')
                });
            },
            actionTitle: function(webinar) {
                if (webinar.registered) {
                    return 'Already Registered';
                } else {
                    return 'Register for Webinar';
                }
            }
        }
    }
</script>

<style scoped>

</style>
