export default {
  epprStandards: {
    EPRR01: {
      title: "Specialist/Advisor Portfolio Workbook",
      select: [
        "A1",
        "A3",
        "B1",
        "C1",
        "D1",
        "D2",
        "E3",
        "F1",
        "F2",
        "G1",
        "G2",
        "H1",
        "H2",
      ],
      hide: [],
      reminder: 36,
    },
    EPRR02: {
      title:
        "Principles of Health Command – Strategic & Tactical Health Commander",
      select: [
        "A1",
        "A2",
        "A3",
        "B1",
        "C1",
        "D1",
        "E3",
        "F1",
        "F2",
        "G1",
        "G2F",
      ],
      hide: [],
      reminder: 36,
    },
    EPRR03: {
      title: "Diploma in Health EPRR (or equivalent)",
      select: [
        "A1",
        "A3",
        "B1",
        "C1",
        "D1",
        "E1",
        "F1",
        "G1",
        "G2",
        "G3",
        "G4",
      ],
      hide: [],
      reminder: 0,
    },
    EPRR04: {
      title: "Legal Awareness Training (Optional)",
      select: ["A1", "A2", "A3", "F1", "G1", "G2"],
      hide: [],
      reminder: 36,
    },
    EPRR05: {
      title: "Defence Contribution to Resilience (or equivalent) (Optional)",
      select: ["A1", "A3", "F1", "G1", "G2", "G3"],
      hide: [],
      reminder: 36,
    },
    EPRR06: {
      title: "MAGIC (or MAGIC-Lite) (Optional)",
      select: ["A1", "A2", "A3", "B1", "C1", "G1", "H2"],
      hide: [],
      reminder: 36,
    },
    EPRR07: {
      title: "Media Training/Awareness",
      select: ["F2", "G1", "G2", "G3"],
      hide: [],
      reminder: 36,
    },
    EPRR08: {
      title: "Working with your loggist",
      select: ["A1", "G1", "G2"],
      hide: [],
      reminder: 36,
    },
    EPRR09: {
      title: "JESIP Awareness/Training",
      select: ["A1", "A2", "B1", "F1"],
      hide: [],
      reminder: 12,
    },
    EPRR10: {
      title: "Structured Debrief Training",
      select: ["A2", "E2"],
      hide: [],
      reminder: 0,
    },
    EPRR11: {
      title: "CBRN Training (including PRPS)",
      select: ["B1", "C1", "E1", "E2"],
      hide: [],
      reminder: 36,
    },
    EPRR12: {
      title: "ESORT Awareness (Optional)",
      select: ["B1", "C1", "E2"],
      hide: [],
      reminder: 36,
    },
    EPRR15: {
      title: "Senior Emergo Instructor (Optional)",
      select: ["E1", "E2"],
      hide: [],
      reminder: 0,
    },
    EPRR16: {
      title: "Business Continuity Awareness",
      select: ["G1", "G2"],
      hide: [],
      reminder: 36,
    },
    EPRR17: {
      title:
        "Joint Decision-Making Awareness (initially through training and then annually through exercise application) (Optional)",
      select: ["A2", "A3", "B1", "E3", "F2", "G1", "G2"],
      hide: [],
      reminder: 12,
    },
    EPRR18: {
      title: "Local Resilience Forum Awareness (Optional)",
      select: ["A1", "A2", "A3", "B1", "E3", "F1", "F2", "G1", "G2"],
      hide: [],
      reminder: 36,
    },
    EPRR19: {
      title: "Specialist Asset Awareness (Optional)",
      select: ["C1", "F1", "G1", "G2"],
      hide: [],
      reminder: 36,
    },
    EPRR20: {
      title: "Equality, Diversity & Inclusion within EPRR",
      select: ["F1", "F2", "G1", "G2", "G3", "G4", "H1", "H2"],
      hide: [],
      reminder: 12,
    },
    EPRR21: {
      title:
        "Facilitate a debrief (inc. through exercises with a minimum of one structured debrief within 3 years)",
      select: ["A2", "A3", "C1", "E3", "F1", "F2"],
      hide: [],
      reminder: 12,
    },
    EPRR22: {
      title: "Undertake risk assessments",
      select: ["A2", "C1", "D1", "E2"],
      hide: [],
      reminder: 12,
    },
    EPRR23: {
      title: "Develop and maintain plans (EPRR)",
      select: ["A3", "B1", "C1", "D1", "H2"],
      hide: [],
      reminder: 12,
    },
    EPRR24: {
      title: "Develop and maintain plans (BCM)",
      select: ["B1", "C1", "D1", "E1", "E2"],
      hide: [],
      reminder: 12,
    },
    EPRR25: {
      title:
        "Develop and Deliver Training & Exercising (internally within your organisation)",
      select: ["B1", "C1", "D1", "E1", "E3", "F1"],
      hide: [],
      reminder: 12,
    },
    EPRR27: {
      title:
        "Writing a Strategy (inc. through exercise application) (Optional)",
      select: ["B1", "C1", "D1", "E1", "E2", "E3", "F1", "F2"],
      hide: [],
      reminder: 12,
    },
    EPRR28: {
      title:
        "Writing a Tactical Plan (inc. through exercise application) (Optional)",
      select: ["A2", "B1", "C1", "E3", "F2", "G4", "H1"],
      hide: [],
      reminder: 12,
    },
    EPRR29: {
      title:
        "Demonstrate an understanding of the competencies required to chair a strategic level meeting. For those individuals fulfilling the role of a Strategic Health Commander they will be required to chair a Strategic meeting (either through incident or exercise)",
      select: ["A2", "B1", "C1", "F2", "G4", "H1"],
      hide: [],
      reminder: 12,
    },
    EPRR30: {
      title:
        "Demonstrate an understanding of the competencies required to chair a Tactical Level Meeting – individuals fulfilling Tactical Health Commander roles are required to chair a meeting",
      select: ["A3", "B1", "G2"],
      hide: [],
      reminder: 12,
    },
    EPRR31: {
      title:
        "Act as a Tactical or Strategic Advisor at an incident or exercise",
      select: [
        "A1",
        "A2",
        "A3",
        "B1",
        "C1",
        "E3",
        "F2",
        "G1",
        "G2",
        "G4",
        "H1",
        "H2",
      ],
      hide: [],
      reminder: 12,
    },
    EPRR32: {
      title:
        "If fulfilling an on-call function at either strategic or tactical level, to act as a Health Commander at an incident or exercise as per their relevant command level (e.g. Strategic or Tactical)",
      select: [
        "A2",
        "A3",
        "B1",
        "C1",
        "E2",
        "E3",
        "F2",
        "G1",
        "G2",
        "G4",
        "H1",
        "H2",
      ],
      hide: [],
      reminder: 12,
    },
    SHC01: {
      title: "Strategic Health Commander Portfolio Workbook",
      select: [
        "A1",
        "A3",
        "B1",
        "C1",
        "D1",
        "D2",
        "E3",
        "F1",
        "F2",
        "G1",
        "H1",
        "H2",
      ],
      hide: [],
      reminder: 36,
    },
    SHC02: {
      title: "Principles of Health Command – Strategic Health Commander",
      select: ["A1", "A2", "A3", "B1", "C1", "D1", "E3", "F2", "G1"],
      hide: [],
      reminder: 36,
    },
    SHC03: {
      title: "Legal Awareness Training",
      select: ["A1", "A2", "A3", "G1"],
      hide: [],
      reminder: 36,
    },
    SHC04: {
      title: "Defence Contribution to Resilience (or equivalent) (Optional)",
      select: ["A1"],
      hide: [],
      reminder: 36,
    },
    SHC05: {
      title: "MAGIC (or MAGIC-Lite) (Optional)",
      select: ["A1", "A2", "A3", "B1", "C1", "E3", "F1", "G4", "H1", "H2"],
      hide: [],
      reminder: 36,
    },
    SHC06: {
      title: "Media Training/Awareness",
      select: ["A2"],
      hide: [],
      reminder: 36,
    },
    SHC07: {
      title: "Working with your loggist",
      select: ["A1", "G1"],
      hide: [],
      reminder: 36,
    },
    SHC08: {
      title: "JESIP Awareness/Training (Optional)",
      select: ["A1", "A2", "B1", "F1"],
      hide: [],
      reminder: 12,
    },
    SHC09: {
      title:
        "Joint Decision-Making Awareness (initially through training and then annually through exercise application)",
      select: ["A2", "A3", "B1", "E3", "G1", "H2"],
      hide: [],
      reminder: 12,
    },
    SHC10: {
      title: "Local Resilience Forum Awareness (Optional)",
      select: ["A1", "A2", "A3", "B1", "E3", "F1", "F2", "G1"],
      hide: [],
      reminder: 36,
    },
    SHC11: {
      title: "Specialist Asset Awareness (Optional)",
      select: ["C1", "F1", "G1"],
      hide: [],
      reminder: 36,
    },
    SHC12: {
      title:
        "EPRR Communications Awareness (initially through training and then annually through exercise application)",
      select: ["A2", "A3", "C1", "F1", "F2"],
      hide: [],
      reminder: 12,
    },
    SHC13: {
      title:
        "Incident Response Plan/ Command & Control familiarisation (inc. through exercise application)",
      select: ["A3", "B1", "C1", "D1", "E3", "F1", "G1", "G4", "H1"],
      hide: [],
      reminder: 12,
    },
    SHC14: {
      title: "Writing a Strategy (inc. through exercise application)",
      select: ["A2", "B1", "C1", "E3", "F2", "G4", "H1"],
      hide: [],
      reminder: 12,
    },
    SHC15: {
      title: "Chair a Strategic Level Meeting",
      select: ["A3", "B1"],
      hide: [],
      reminder: 12,
    },
    SHC16: {
      title: "Act as a Strategic Health Commander at an incident or exercise",
      select: [
        "A1",
        "A2",
        "A3",
        "B1",
        "C1",
        "E3",
        "F2",
        "G1",
        "G4",
        "H1",
        "H2",
      ],
      hide: [],
      reminder: 12,
    },
    SHC17: {
      title:
        "Act as a Strategic Health Commander at an Incident or exercise with Multi-agency partners (Optional)",
      select: ["A1", "A2", "A3", "B1", "C1", "E3", "F2", "G4", "H1", "H2"],
      hide: [],
      reminder: 24,
    },
    SCH18: {
      title: "Accountable Emergency Officers – Role & Expectations (AEO Only)",
      select: ["C1", "D1", "D2"],
      hide: [],
      reminder: 36,
    },
    SHC19: {
      title: "Business Continuity Awareness (AEO Only)",
      select: ["G1"],
      hide: [],
      reminder: 36,
    },
    SHC20: {
      title: "Equality Diversity & Inclusion Awareness for EPRR (Optional)",
      select: [],
      hide: [],
      reminder: 12,
    },
    THC01: {
      title: "Tactical Health Commander Portfolio Workbook",
      select: [
        "A1",
        "A3",
        "B1",
        "C1",
        "D1",
        "E3",
        "F1",
        "F2",
        "G2",
        "G4",
        "H1",
        "H2",
      ],
      hide: [],
      reminder: 36,
    },
    THC02: {
      title: "Principles of Health Command – Tactical Health Commander",
      select: ["A1", "A2", "A3", "B1", "C1", "D1", "E3", "F2", "G2"],
      hide: [],
      reminder: 36,
    },
    THC03: {
      title: "Legal Awareness Training (Optional)",
      select: ["A1", "A2", "A3", "G2"],
      hide: [],
      reminder: 36,
    },
    THC04: {
      title: "Working with your loggist",
      select: ["A1", "G2"],
      hide: [],
      reminder: 36,
    },
    THC05: {
      title:
        "Joint Decision-Making Awareness (initially through training and then annually through exercise application)",
      select: ["A2", "A3", "B1", "E3", "G2", "H2"],
      hide: [],
      reminder: 12,
    },
    THC06: {
      title: "Local Resilience Forum Awareness (Optional)",
      select: ["A1", "A2", "A3", "B1", "E3", "F1", "F2"],
      hide: [],
      reminder: 36,
    },
    THC07: {
      title: "Specialist Asset Awareness (Optional)",
      select: ["C1", "F1", "G2"],
      hide: [],
      reminder: 36,
    },
    THC08: {
      title: "JESIP Awareness (Optional)",
      select: ["A1", "A2", "B1", "F1"],
      hide: [],
      reminder: 12,
    },
    THC09: {
      title:
        "Incident Response Plan/Command & Control familiarisation (including through application at an incident or exercise)",
      select: ["A2", "A3", "B1", "C1", "D1", "E3", "F1", "G2", "G4", "H1"],
      hide: [],
      reminder: 12,
    },
    THC10: {
      title: "Writing a Tactical Plan (inc. through exercise application)",
      select: ["B1", "C1", "E3", "F2", "G4", "H1"],
      hide: [],
      reminder: 12,
    },
    THC11: {
      title: "Chair a Tactical Level Meeting",
      select: ["A3", "B1", "G2"],
      hide: [],
      reminder: 12,
    },
    THC12: {
      title: "Act as a Tactical Health Commander at an incident or exercise",
      select: [
        "A1",
        "A2",
        "A3",
        "B1",
        "C1",
        "E3",
        "F2",
        "G2",
        "G4",
        "H1",
        "H2",
      ],
      hide: [],
      reminder: 12,
    },
    THC13: {
      title:
        "Act as a Tactical Health Commander at an Incident or exercise with Multi-agency partners (Optional)",
      select: [
        "A1",
        "A2",
        "A3",
        "B1",
        "C1",
        "E3",
        "F2",
        "G2",
        "G4",
        "H1",
        "H2",
      ],
      hide: [],
      reminder: 24,
    },
    THC14: {
      title:
        "EPRR Communications Awareness (initially through training and then annually through exercise application)",
      select: ["A2", "A3", "C1", "F1", "F2"],
      hide: [],
      reminder: 12,
    },
    THC15: {
      title: "Equality Diversity & Inclusion in EPRR (Awareness) (Optional)",
      select: [],
      hide: [],
      reminder: 12,
    },
  },
};
