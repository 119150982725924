<template>
    <b-form-group label-class="mb-0 label-title" :label="item.title" label-for="dropdown-form__item" :description="item.helpText">
        <b-form-select v-model="item.value" id="dropdown-form__item" v-on:change="valueChanged">
            <option disabled value="">-- Please Select --</option>
            <option v-for="option in options || item.values" :key="option">{{option}}</option>
        </b-form-select>
    </b-form-group>
</template>

<script>
    import FormItem from './FormItem'

    export default {
        name: "DropdownFormItem",
        extends: FormItem,
        props: ['item', 'options'],
        methods:
          {
            valueChanged(event)
            {
              this.$emit('valueChanged', event)
            }
      }
    }
</script>

<style scoped>

</style>